import { Product } from '@framework/types';
import { useMemo } from 'react';

export function getPrice(product: Product): string {
  let variantsPriceRange: number[] = [];
  if (product?.variations?.length) {
    variantsPriceRange = product.variations
      .map((variation) => variation.price)
      .sort((a, b) => a - b);
  }

  if (variantsPriceRange.length > 1) {
    const minPrice = formatPrice({
      amount: variantsPriceRange[0],
      currencyCode: 'USD',
      locale: 'en-US',
    });
    const maxPrice = formatPrice({
      amount: variantsPriceRange[variantsPriceRange.length - 1],
      currencyCode: 'USD',
      locale: 'en-US',
    });
    return `${minPrice} - ${maxPrice}`;
  } else {
    return formatPrice({
      amount: product.sale_price ? product.sale_price : product.price,
      currencyCode: 'USD',
      locale: 'en-US',
    });
  }
}

export function formatPrice({
  amount,
  currencyCode,
  locale,
}: {
  amount: number;
  currencyCode: string;
  locale: string;
}) {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });

  return formatCurrency.format(amount);
}

export function formatVariantPrice({
  amount,
  baseAmount,
  currencyCode,
  locale,
}: {
  baseAmount: number;
  amount: number;
  currencyCode: string;
  locale: string;
}) {
  const hasDiscount = baseAmount > amount;
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' });
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null;

  const price = formatPrice({ amount, currencyCode, locale });
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currencyCode, locale })
    : null;

  return { price, basePrice, discount };
}

export default function usePrice(
  data?: {
    amount: number;
    baseAmount?: number;
    currencyCode: string;
  } | null,
) {
  const { amount, baseAmount, currencyCode } = data ?? {};
  const locale = 'en';
  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) return '';

    return baseAmount
      ? formatVariantPrice({ amount, baseAmount, currencyCode, locale })
      : formatPrice({ amount, currencyCode, locale });
  }, [amount, baseAmount, currencyCode]);

  return typeof value === 'string'
    ? { price: value, basePrice: null, discount: null }
    : value;
}
